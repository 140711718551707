import { ColorV1 } from "../constants/color"

export const getBorderColorV1Class = (color?: ColorV1): string => {
  let classes = ""
  switch (color) {
    case ColorV1.cerulean: {
      classes = "border-cerulean"
      break
    }
    case ColorV1.trinidad: {
      classes = "border-trinidad"
      break
    }
    case ColorV1.aquaDeep: {
      classes = "border-aqua-deep"
      break
    }
    case ColorV1.orangePeel: {
      classes = "border-orange-peel"
      break
    }
    case ColorV1.white: {
      classes = "border-white"
      break
    }
    case ColorV1.tuftBush: {
      classes = "border-tuft-bush"
      break
    }
    case ColorV1.gray: {
      classes = "border-gray"
      break
    }
    case ColorV1.mystic: {
      classes = "border-mystic"
      break
    }
    case ColorV1.mineShaftDark: {
      classes = "border-mine-shaft-dark"
      break
    }
    case ColorV1.sandyBeach: {
      classes = "border-sandy-beach"
      break
    }
    case ColorV1.flamingo: {
      classes = "border-flamingo"
      break
    }
    case ColorV1.deepSeaGreen: {
      classes = "border-deep-sea-green"
      break
    }
    case ColorV1.black: {
      classes = "border-black"
      break
    }
    case ColorV1.juniper: {
      classes = "border-juniper"
      break
    }
    case ColorV1.pippin: {
      classes = "border-pippin"
      break
    }
    case ColorV1.hummingBird: {
      classes = "border-humming-bird"
      break
    }
    case ColorV1.opal: {
      classes = "border-opal"
      break
    }
    case ColorV1.glassdoorGreen: {
      classes = "border-glassdoor-green"
      break
    }
    case ColorV1.bloodOrange: {
      classes = "border-blood-orange"
      break
    }
    case ColorV1.charcoal: {
      classes = "border-charcoal"
      break
    }
    default:
      classes = ""
  }
  return classes
}

export const getBorderHoverColorV1Class = (color?: ColorV1): string => {
  let classes = ""
  switch (color) {
    case ColorV1.cerulean: {
      classes = "hover:border-cerulean"
      break
    }
    case ColorV1.trinidad: {
      classes = "hover:border-trinidad"
      break
    }
    case ColorV1.aquaDeep: {
      classes = "hover:border-aqua-deep"
      break
    }
    case ColorV1.orangePeel: {
      classes = "hover:border-orange-peel"
      break
    }
    case ColorV1.white: {
      classes = "hover:border-white"
      break
    }
    case ColorV1.tuftBush: {
      classes = "hover:border-tuft-bush"
      break
    }
    case ColorV1.mineShaftDark: {
      classes = "hover:border-mine-shaft-dark"
      break
    }
    case ColorV1.gray: {
      classes = "hover:border-gray"
      break
    }
    case ColorV1.sandyBeach: {
      classes = "hover:border-sandy-beach"
      break
    }
    case ColorV1.flamingo: {
      classes = "hover:border-flamingo"
      break
    }
    case ColorV1.deepSeaGreen: {
      classes = "hover:deep-sea-green"
      break
    }
    case ColorV1.black: {
      classes = "hover:border-black"
      break
    }
    case ColorV1.sazerac: {
      classes = "hover:border-sazerac"
      break
    }
    case ColorV1.juniper: {
      classes = "hover:border-juniper"
      break
    }
    case ColorV1.pippin: {
      classes = "hover:border-pippin"
      break
    }
    case ColorV1.hummingBird: {
      classes = "hover:border-humming-bird"
      break
    }
    case ColorV1.opal: {
      classes = "hover:border-opal"
      break
    }
    case ColorV1.glassdoorGreen: {
      classes = "hover:border-glassdoor-green"
      break
    }
    case ColorV1.bloodOrange: {
      classes = "hover:border-blood-orange"
      break
    }
    case ColorV1.charcoal: {
      classes = "hover:border-charcoal"
      break
    }
    default:
      classes = ""
  }
  return classes
}

export const getTextColorV1Class = (color?: ColorV1): string => {
  let classes = ""
  switch (color) {
    case ColorV1.cerulean: {
      classes = "text-cerulean"
      break
    }
    case ColorV1.trinidad: {
      classes = "text-trinidad"
      break
    }
    case ColorV1.aquaDeep: {
      classes = "text-aqua-deep"
      break
    }
    case ColorV1.orangePeel: {
      classes = "text-orange-peel"
      break
    }
    case ColorV1.white: {
      classes = "text-white"
      break
    }
    case ColorV1.tuftBush: {
      classes = "text-tuft-bush"
      break
    }
    case ColorV1.gray: {
      classes = "text-gray"
      break
    }
    case ColorV1.doveGray: {
      classes = "text-dove-grey"
      break
    }
    case ColorV1.mineShaftDark: {
      classes = "text-mine-shaft-dark"
      break
    }
    case ColorV1.sandyBeach: {
      classes = "text-sandy-beach"
      break
    }
    case ColorV1.fairPink: {
      classes = "text-fair-pink"
      break
    }
    case ColorV1.mystic: {
      classes = "text-mystic"
      break
    }
    case ColorV1.flamingo: {
      classes = "text-flamingo"
      break
    }
    case ColorV1.deepSeaGreen: {
      classes = "text-deep-sea-green"
      break
    }
    case ColorV1.black: {
      classes = "text-black"
      break
    }
    case ColorV1.sazerac: {
      classes = "text-sazerac"
      break
    }
    case ColorV1.juniper: {
      classes = "text-juniper"
      break
    }
    case ColorV1.pippin: {
      classes = "text-pippin"
      break
    }
    case ColorV1.hummingBird: {
      classes = "text-humming-bird"
      break
    }
    case ColorV1.opal: {
      classes = "text-opal"
      break
    }
    case ColorV1.glassdoorGreen: {
      classes = "text-glassdoor-green"
      break
    }
    case ColorV1.bloodOrange: {
      classes = "text-blood-orange"
      break
    }
    case ColorV1.naartjie: {
      classes = "text-naartjie"
      break
    }
    case ColorV1.charcoal: {
      classes = "text-charcoal"
      break
    }
    default:
      classes = ""
  }
  return classes
}

export const getBackgroundColorV1Class = (color?: ColorV1): string => {
  let classes = ""
  switch (color) {
    case ColorV1.cerulean: {
      classes = "bg-cerulean"
      break
    }
    case ColorV1.trinidad: {
      classes = "bg-trinidad"
      break
    }
    case ColorV1.aquaDeep: {
      classes = "bg-aqua-deep"
      break
    }
    case ColorV1.orangePeel: {
      classes = "bg-orange-peel"
      break
    }
    case ColorV1.white: {
      classes = "bg-white"
      break
    }
    case ColorV1.tuftBush: {
      classes = "bg-tuft-bush"
      break
    }
    case ColorV1.mineShaftDark: {
      classes = "bg-mine-shaft-dark"
      break
    }
    case ColorV1.gray: {
      classes = "bg-gray"
      break
    }
    case ColorV1.fairPink: {
      classes = "bg-fair-pink"
      break
    }
    case ColorV1.mystic: {
      classes = "bg-mystic"
      break
    }
    case ColorV1.sandyBeach: {
      classes = "bg-sandy-beach"
      break
    }
    case ColorV1.flamingo: {
      classes = "bg-flamingo"
      break
    }
    case ColorV1.deepSeaGreen: {
      classes = "bg-deep-sea-green"
      break
    }
    case ColorV1.black: {
      classes = "bg-black"
      break
    }
    case ColorV1.sazerac: {
      classes = "bg-sazerac"
      break
    }
    case ColorV1.juniper: {
      classes = "bg-juniper"
      break
    }
    case ColorV1.pippin: {
      classes = "bg-pippin"
      break
    }
    case ColorV1.hummingBird: {
      classes = "bg-humming-bird"
      break
    }
    case ColorV1.opal: {
      classes = "bg-opal"
      break
    }
    case ColorV1.glassdoorGreen: {
      classes = "bg-glassdoor-green"
      break
    }
    case ColorV1.bloodOrange: {
      classes = "bg-blood-orange"
      break
    }
    case ColorV1.naartjie: {
      classes = "bg-naartjie"
      break
    }
    case ColorV1.charcoal: {
      classes = "bg-charcoal"
      break
    }
    default:
      classes = ""
  }
  return classes
}

export const getBackgroundHoverColorV1Class = (color?: ColorV1): string => {
  let classes = ""
  switch (color) {
    case ColorV1.cerulean: {
      classes = "hover:bg-cerulean"
      break
    }
    case ColorV1.trinidad: {
      classes = "hover:bg-trinidad"
      break
    }
    case ColorV1.aquaDeep: {
      classes = "hover:bg-aqua-deep"
      break
    }
    case ColorV1.orangePeel: {
      classes = "hover:bg-orange-peel"
      break
    }
    case ColorV1.white: {
      classes = "hover:bg-white"
      break
    }
    case ColorV1.tuftBush: {
      classes = "hover:bg-tuft-bush"
      break
    }
    case ColorV1.mineShaftDark: {
      classes = "hover:bg-mine-shaft-dark"
      break
    }
    case ColorV1.gray: {
      classes = "hover:bg-gray"
      break
    }
    case ColorV1.sandyBeach: {
      classes = "hover:bg-sandy-beach"
      break
    }
    case ColorV1.flamingo: {
      classes = "hover:bg-flamingo"
      break
    }
    case ColorV1.black: {
      classes = "hover:bg-black"
      break
    }
    case ColorV1.sazerac: {
      classes = "hover:bg-sazerac"
      break
    }
    case ColorV1.juniper: {
      classes = "hover:bg-juniper"
      break
    }
    case ColorV1.pippin: {
      classes = "hover:bg-pippin"
      break
    }
    case ColorV1.hummingBird: {
      classes = "hover:bg-humming-bird"
      break
    }
    case ColorV1.opal: {
      classes = "hover:bg-opal"
      break
    }
    case ColorV1.glassdoorGreen: {
      classes = "hover:bg-glassdoor-green"
      break
    }
    case ColorV1.bloodOrange: {
      classes = "hover:bg-blood-orange"
      break
    }
    case ColorV1.charcoal: {
      classes = "hover:bg-charcoal"
      break
    }
    default:
      classes = "hover:bg-cerulean"
  }
  return classes
}

export const getTextHoverColorV1Class = (color?: ColorV1): string => {
  let classes = ""
  switch (color) {
    case ColorV1.cerulean: {
      classes = "hover:text-cerulean"
      break
    }
    case ColorV1.trinidad: {
      classes = "hover:text-trinidad"
      break
    }
    case ColorV1.aquaDeep: {
      classes = "hover:text-aqua-deep"
      break
    }
    case ColorV1.orangePeel: {
      classes = "hover:text-orange-peel"
      break
    }
    case ColorV1.white: {
      classes = "hover:text-white"
      break
    }
    case ColorV1.tuftBush: {
      classes = "hover:text-tuft-bush"
      break
    }
    case ColorV1.mineShaftDark: {
      classes = "hover:text-mine-shaft-dark"
      break
    }
    case ColorV1.gray: {
      classes = "hover:text-gray"
      break
    }
    case ColorV1.sandyBeach: {
      classes = "hover:text-sandy-beach"
      break
    }
    case ColorV1.flamingo: {
      classes = "hover:text-flamingo"
      break
    }
    case ColorV1.deepSeaGreen: {
      classes = "text-deep-sea-green"
      break
    }
    case ColorV1.black: {
      classes = "hover:text-black"
      break
    }
    case ColorV1.sazerac: {
      classes = "hover:text-sazerac"
      break
    }
    case ColorV1.juniper: {
      classes = "hover:text-juniper"
      break
    }
    case ColorV1.pippin: {
      classes = "hover:text-pippin"
      break
    }
    case ColorV1.hummingBird: {
      classes = "hover:text-humming-bird"
      break
    }
    case ColorV1.opal: {
      classes = "hover:text-opal"
      break
    }
    case ColorV1.glassdoorGreen: {
      classes = "hover:text-glassdoor-green"
      break
    }
    case ColorV1.bloodOrange: {
      classes = "hover:text-blood-orange"
      break
    }
    case ColorV1.charcoal: {
      classes = "hover:text-charcoal"
      break
    }
    default:
      classes = ""
  }
  return classes
}

export const getTextGroupHoverColorV1Class = (color?: ColorV1): string => {
  let classes = ""
  switch (color) {
    case ColorV1.cerulean: {
      classes = "group-hover:text-cerulean"
      break
    }
    case ColorV1.trinidad: {
      classes = "group-hover:text-trinidad"
      break
    }
    case ColorV1.aquaDeep: {
      classes = "group-hover:text-aqua-deep"
      break
    }
    case ColorV1.orangePeel: {
      classes = "group-hover:text-orange-peel"
      break
    }
    case ColorV1.white: {
      classes = "group-hover:text-white"
      break
    }
    case ColorV1.tuftBush: {
      classes = "group-hover:text-tuft-bush"
      break
    }
    case ColorV1.mineShaftDark: {
      classes = "group-hover:text-mine-shaft-dark"
      break
    }
    case ColorV1.gray: {
      classes = "group-hover:text-gray"
      break
    }
    case ColorV1.sandyBeach: {
      classes = "group-hover:text-sandy-beach"
      break
    }
    case ColorV1.flamingo: {
      classes = "group-hover:text-flamingo"
      break
    }
    case ColorV1.deepSeaGreen: {
      classes = "text-deep-sea-green"
      break
    }
    case ColorV1.black: {
      classes = "group-hover:text-black"
      break
    }
    case ColorV1.juniper: {
      classes = "group-hover:text-juniper"
      break
    }
    case ColorV1.pippin: {
      classes = "group-hover:text-pippin"
      break
    }
    case ColorV1.hummingBird: {
      classes = "group-hover:text-humming-bird"
      break
    }
    case ColorV1.opal: {
      classes = "group-hover:text-opal"
      break
    }
    case ColorV1.glassdoorGreen: {
      classes = "group-hover:text-glassdoor-green"
      break
    }
    case ColorV1.bloodOrange: {
      classes = "group-hover:text-blood-orange"
      break
    }
    case ColorV1.charcoal: {
      classes = "group-hover:text-charcoal"
      break
    }
    default:
      classes = ""
  }
  return classes
}

export const getBorderGroupHoverColorV1Class = (color?: ColorV1): string => {
  let classes = ""
  switch (color) {
    case ColorV1.cerulean: {
      classes = "group-hover:border-cerulean"
      break
    }
    case ColorV1.trinidad: {
      classes = "group-hover:border-trinidad"
      break
    }
    case ColorV1.aquaDeep: {
      classes = "group-hover:border-aqua-deep"
      break
    }
    case ColorV1.orangePeel: {
      classes = "group-hover:border-orange-peel"
      break
    }
    case ColorV1.white: {
      classes = "group-hover:border-white"
      break
    }
    case ColorV1.tuftBush: {
      classes = "group-hover:border-tuft-bush"
      break
    }
    case ColorV1.mineShaftDark: {
      classes = "group-hover:border-mine-shaft-dark"
      break
    }
    case ColorV1.gray: {
      classes = "group-hover:border-gray"
      break
    }
    case ColorV1.sandyBeach: {
      classes = "group-hover:border-sandy-beach"
      break
    }
    case ColorV1.flamingo: {
      classes = "group-hover:border-flamingo"
      break
    }
    case ColorV1.deepSeaGreen: {
      classes = "group-hover:border-deep-sea-green"
      break
    }
    case ColorV1.black: {
      classes = "group-hover:border-black"
      break
    }
    case ColorV1.sazerac: {
      classes = "group-hover:border-sazerac"
      break
    }
    case ColorV1.juniper: {
      classes = "group-hover:border-juniper"
      break
    }
    case ColorV1.pippin: {
      classes = "group-hover:border-pippin"
      break
    }
    case ColorV1.hummingBird: {
      classes = "group-hover:border-humming-bird"
      break
    }
    case ColorV1.opal: {
      classes = "group-hover:border-opal"
      break
    }
    case ColorV1.glassdoorGreen: {
      classes = "group-hover:border-glassdoor-green"
      break
    }
    case ColorV1.bloodOrange: {
      classes = "group-hover:border-blood-orange"
      break
    }
    case ColorV1.charcoal: {
      classes = "group-hover:border-charcoal"
      break
    }
    default:
      classes = ""
  }
  return classes
}

export const getColorV1Class = (color?: ColorV1): string => {
  let classes = ""
  switch (color) {
    case ColorV1.cerulean: {
      classes = "cerulean"
      break
    }
    case ColorV1.trinidad: {
      classes = "trinidad"
      break
    }
    case ColorV1.aquaDeep: {
      classes = "aqua-deep"
      break
    }
    case ColorV1.orangePeel: {
      classes = "orange-peel"
      break
    }
    case ColorV1.white: {
      classes = "white"
      break
    }
    case ColorV1.tuftBush: {
      classes = "tuft-bush"
      break
    }
    case ColorV1.mineShaftDark: {
      classes = "mine-shaft-dark"
      break
    }
    case ColorV1.gray: {
      classes = "gray"
      break
    }
    case ColorV1.sandyBeach: {
      classes = "sandy-beach"
      break
    }
    case ColorV1.flamingo: {
      classes = "flamingo"
      break
    }
    case ColorV1.deepSeaGreen: {
      classes = "deep-sea-green"
      break
    }
    case ColorV1.black: {
      classes = "black"
      break
    }
    case ColorV1.sazerac: {
      classes = "sazerac"
      break
    }
    case ColorV1.juniper: {
      classes = "juniper"
      break
    }
    case ColorV1.pippin: {
      classes = "pippin"
      break
    }
    case ColorV1.hummingBird: {
      classes = "humming-bird"
      break
    }
    case ColorV1.opal: {
      classes = "opal"
      break
    }
    case ColorV1.glassdoorGreen: {
      classes = "glassdoor-green"
      break
    }
    case ColorV1.bloodOrange: {
      classes = "blood-orange"
      break
    }
    case ColorV1.charcoal: {
      classes = "blood-charcoal"
      break
    }
    default:
      classes = ""
  }
  return classes
}

export const getTextDecorationColorV1Class = (color?: ColorV1): string => {
  let classes = ""
  switch (color) {
    case ColorV1.cerulean: {
      classes = "decoration-cerulean"
      break
    }
    case ColorV1.trinidad: {
      classes = "decoration-trinidad"
      break
    }
    case ColorV1.aquaDeep: {
      classes = "decoration-aqua-deep"
      break
    }
    case ColorV1.orangePeel: {
      classes = "decoration-orange-peel"
      break
    }
    case ColorV1.white: {
      classes = "decoration-white"
      break
    }
    case ColorV1.tuftBush: {
      classes = "decoration-tuft-bush"
      break
    }
    case ColorV1.mineShaftDark: {
      classes = "decoration-mine-shaft-dark"
      break
    }
    case ColorV1.gray: {
      classes = "decoration-gray"
      break
    }
    case ColorV1.sandyBeach: {
      classes = "decoration-sandy-beach"
      break
    }
    case ColorV1.flamingo: {
      classes = "decoration-flamingo"
      break
    }
    case ColorV1.deepSeaGreen: {
      classes = "decoration-deep-sea-green"
      break
    }
    case ColorV1.black: {
      classes = "decoration-black"
      break
    }
    case ColorV1.sazerac: {
      classes = "decoration-sazerac"
      break
    }
    case ColorV1.juniper: {
      classes = "decoration-juniper"
      break
    }
    case ColorV1.pippin: {
      classes = "decoration-pippin"
      break
    }
    case ColorV1.hummingBird: {
      classes = "decoration-humming-bird"
      break
    }
    case ColorV1.opal: {
      classes = "decoration-opal"
      break
    }
    case ColorV1.glassdoorGreen: {
      classes = "decoration-glassdoor-green"
      break
    }
    case ColorV1.bloodOrange: {
      classes = "decoration-blood-orange"
      break
    }
    case ColorV1.charcoal: {
      classes = "decoration-charcoal"
      break
    }
    default:
      classes = ""
  }
  return classes
}
