export enum ColorV1 {
  naartjie = "naartjie",
  cerulean = "cerulean",
  orangePeel = "orangePeel",
  trinidad = "trinidad",
  aquaDeep = "aquaDeep",
  white = "white",
  gray = "gray",
  doveGray = "doveGray",
  mineShaftDark = "mineShaftDark",
  sandyBeach = "sandyBeach",
  tuftBush = "tuftBush",
  black = "black",
  fairPink = "fairPink",
  mystic = "mystic",
  flamingo = "flamingo",
  deepSeaGreen = "deepSeaGreen",
  opal = "opal",
  sazerac = "sazerac",
  juniper = "juniper",
  pippin = "pippin",
  hummingBird = "hummingBird",
  glassdoorGreen = "glassdoorGreen",
  bloodOrange = "bloodOrange",
  charcoal = "charcoal",
}

export const ColorKey = {
  naartjie: "naartjie",
  cerulean: "cerulean",
  orangePeel: "orange-peel",
  trinidad: "trinidad",
  aquaDeep: "aqua-deep",
  white: "white",
  gray: "gray",
  doveGray: "dove-gray",
  mineShaftDark: "mine-shaft-dark",
  sandyBeach: "sandy-beach",
  tuftBush: "tuft-bush",
  black: "black",
  fairPink: "fair-pink",
  mystic: "mystic",
  flamingo: "flamingo",
  deepSeaGreen: "deep-sea-green",
  opal: "opal",
  sazerac: "sazerac",
  juniper: "juniper",
  pippin: "pippin",
  hummingBird: "humming-bird",
  glassdoorGreen: "glassdoor-green",
  bloodOrange: "blood-orange",
  charcoal: "charcoal",
}
